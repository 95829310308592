import { UserService, AuthenticationError } from '../services/user.service';
import { TokenService } from '../services/storage.service';
import router from '../router/index';

const state = {
	authenticating: false,
	accessToken: TokenService.getToken(),
	AuthenticationErrorCode: 0,
	AuthenticationError: 'Error'
};

const getters = {
	loggedIn: (state) => {
		return state.accessToken;
	},

	AuthenticationErrorCode: (state) => {
		return state.AuthenticationErrorCode;
	},

	AuthenticationError: (state) => {
		return state.AuthenticationError;
	},

	authenticating: (state) => {
		return state.authenticating;
	}
};

const actions = {
	async login({ commit }, body) {
		commit('initRequest');
		try {
			commit('loadingState', true);
			const response = await UserService.login(body);
			commit('loginSuccess', response.token);

			router.push('/usuario/credito');
			return;
		} catch (e) {
			// console.log('Error: ', e);
			if (e instanceof AuthenticationError) {
				commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
			}
		} finally {
			commit('loadingState', false);
		}
	},

	async loginAdmin({ commit }, body) {
		commit('initRequest');

		try {
			commit('loadingState', true);
			const response = await UserService.login(body);

			commit('loginSuccess', response.token);

			//const profileData = await UserService.getProfile();
			// Get profile basic info on login
			// commit('user/setProfileData', profileData, { root: true });

			// Redirect the user to the page he first tried to visit or to the home view
			if (response.rol == 'Administrador') {
				router.push('/admin/inicio');
			} else {
				router.push('/usuario/credito');
			}

			// router.push(router.history.current.query.redirect || '/');

			return;
		} catch (e) {
			// console.log('Error: ', e);
			if (e instanceof AuthenticationError) {
				commit('loginError', { errorCode: e.errorCode, errorMessage: e.message });
			}
		} finally {
			commit('loadingState', false);
		}
	},

	logout({ dispatch }) {
		if (router.currentRoute.path.includes('usuario')) {
			dispatch('logoutUsuario');
		} else {
			dispatch('logoutAdmin');
		}
	},
	
	logoutUsuario({ commit }) {
		UserService.logout();
		commit('logoutSuccess');
		router.push('/usuario/login');
	},

	logoutAdmin({ commit }) {
		UserService.logout();
		commit('logoutSuccess');
		router.push('/admin/login');
	}
};

const mutations = {
	initRequest(state) {
		state.authenticating = true;
		state.AuthenticationError = '';
		state.AuthenticationErrorCode = 0;
	},

	loginSuccess(state, accessToken) {
		state.accessToken = accessToken;
		state.authenticating = false;
	},

	loginError(state, { errorCode, errorMessage }) {
		state.authenticating = false;
		state.AuthenticationErrorCode = errorCode;
		state.AuthenticationError = errorMessage;
	},

	logoutSuccess(state) {
		state.accessToken = '';
	},

	loadingState(state, loadingState) {
		state.authenticating = loadingState;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
