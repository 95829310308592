import axios from 'axios';
import { TokenService } from './storage.service';
import store from '../store';

const ApiService = {
	init(baseURL) {
		axios.defaults.baseURL = baseURL;
	},

	_401Interceptor: null,

	async setHeader() {
		axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
		// axios.defaults.headers.common['Access-Control-Max-Age'] = 86400;
		// axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
	},

	getResource(url) {
		return axios.defaults.baseURL + url;
	},

	getHeader() {
		return axios.defaults.headers.common['Authorization'];
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	async get(resource) {
		return axios.get(resource);
	},

	post(resource, data) {
		return axios.post(resource, data);
	},

	put(resource, data) {
		return axios.put(resource, data);
	},

	delete(resource) {
		return axios.delete(resource);
	},

	customRequest(request) {
		return axios(request);
	},

	mount401Interceptor() {
		this._401Interceptor = axios.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				// Handle error
				if (error.request.status === 401) {
					TokenService.setTokenValidValue(false);

					store.commit('user/setUserAuthError', {
						isError: true,
						authErrorMessage: 'No tienes autorización para ver esta página, vuelve a identificarte'
					});

					store.dispatch('auth/logout');
				} else {
					// If error is not 401
					// store.commit('user/setError', {
					// 	isError: true,
					// 	errorMessage: 'Ha sucedido un error'
					// });
					throw error;
				}
			}
		);
	},

	unmount401Interceptor() {
		// Eject the interceptor
		axios.interceptors.response.eject(this._401Interceptor);
	}
};

export default ApiService;
