import ApiService from '../../services/api.service';

export const fetchAnotaciones = async ({ commit }) => {
	commit('user/initRequest', null, { root: true });
	const url = ApiService.getResource('/trabajador/anotaciones');
	try {
		commit('user/loadingRequest', true, { root: true });
		let response = await ApiService.get(url);
		commit('setListaAnotaciones', response.data.data);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		return false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
};

export const postNuevaAnotacion = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/trabajador/anotaciones');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.post(url, payload);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const putCambiarEstadoTrabajador = async ({ commit }, idTrabajador) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/trabajador/' + idTrabajador + '/cambiar_estado');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.put(url);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const putCambiarPasswordTrabajador = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/usuario/' + payload.idTrabajador + '/changePassword');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.put(url, payload.body);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const deleteAnotacion = async ({ commit }, idAnotacion) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/trabajador/anotaciones/' + idAnotacion);
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.delete(url);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const cambiarTiendaTrabajador = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/trabajador/' + payload.idTrabajador + '/establecimiento');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.put(url, payload);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const deleteTrabajor = async ({ commit }, idTrabajador) => {
	commit('user/initRequest', null, { root: true });
	let status;
	let response;
	const url = ApiService.getResource('/trabajador/' + idTrabajador);
	try {
		commit('user/loadingRequest', true, { root: true });
		response = await ApiService.delete(url);
		status = response.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const deleteProductoTrabajador = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status;
	let response;
	const url = ApiService.getResource('/trabajador/' + payload.idTrabajador + '/producto/' + payload.idProducto);
	try {
		commit('user/loadingRequest', true, { root: true });
		response = await ApiService.delete(url);
		status = response.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};