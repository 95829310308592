const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_VALID = 'valid_token';

localStorage.setItem(TOKEN_VALID, false);

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},

	saveToken(accessToken) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},

	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},

	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},

	saveRefreshToken(refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	},

	// When there is a 401
	setTokenValidValue(value) {
		localStorage.setItem(TOKEN_VALID, value);
	},

	isTokenValid() {
		return localStorage.getItem(TOKEN_VALID);
	}
};

export { TokenService };
