import Vue from 'vue';
import Vuex from 'vuex';
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

Vue.use(Vuex);

const state = {
	listaProductos: []
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
