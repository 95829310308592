import ApiService from '../../services/api.service';

export const fetchListaProducto = async ({ commit }) => {
	commit('user/initRequest', null, { root: true });
	const url = ApiService.getResource('/producto');
	try {
		commit('user/loadingRequest', true, { root: true });
		let response = await ApiService.get(url);
		commit('setListaProductos', response.data.data);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		return false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
};

export const postNuevoProducto = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/producto');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.post(url, payload);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
		
	}
	return status;
};

export const putEditarProducto = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource(`/producto/${payload.idProducto}`);
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.put(url, payload.producto);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
		
	}
	return status;
};
