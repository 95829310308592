import Vue from 'vue';
import VueRouter from 'vue-router';
import { TokenService } from '../services/storage.service';
import ApiService from '../services/api.service';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/usuario/login'
	},
	{
		path: '/usuario/login',
		name: 'Login Usuario',
		component: () => import('../views/user/userLogin.vue'),
		meta: {
			public: true,
			onlyWhenLoggedOut: true
		}
	},
	{
		path: '/usuario',
		name: 'Dashboard Usuario',
		component: () => import('../views/user/userDashboard.vue'),
		children: [
			{
				path: 'credito',
				name: 'Ventas al Crédito',
				component: () => import('../views/user/userVentasCredito.vue'),
				meta: {
					menuIndex: 0
				}
			},
			{
				path: 'contado',
				name: 'Ventas al Contado',
				component: () => import('../views/user/userVentasContado.vue'),
				meta: {
					menuIndex: 1
				}
			},
			{
				path: 'cobranzas',
				name: 'Cobranzas',
				component: () => import('../views/user/userCobranzas.vue'),
				meta: {
					menuIndex: 2
				}
			},
			{
				path: 'anotaciones',
				name: 'Anotaciones',
				component: () => import('../views/user/userAnotaciones.vue')
			},
			{
				path: 'revision',
				name: 'Revisión',
				component: () => import('../views/user/userRevision.vue'),
				meta: {
					menuIndex: 3
				}
			}
		]
	},
	{
		path: '/admin/login',
		name: 'Login Admin',
		component: () => import('../views/admin/adminLogin.vue'),
		meta: {
			public: true,
			onlyWhenLoggedOut: true
		}
	},
	{
		path: '/admin',
		name: 'Dashboard Admin',
		component: () => import('../views/admin/adminDashboard.vue'),
		children: [
			{
				path: 'inicio',
				name: 'Inicio Admin',
				component: () => import('../views/admin/adminInicio.vue'),
				meta: {
					menuIndex: 0,
					restricted: true
				}
			},
			{
				path: 'gestion_movimientos',
				name: 'Gestion Movimientos Admin',
				component: () => import('../views/admin/adminGestionMovimientos.vue'),
				meta: {
					menuIndex: 1,
					restricted: true
				}
			},
			{
				path: 'gestion_cobranzas',
				name: 'Gestion Cobranzas Admin',
				component: () => import('../views/admin/adminGestionCobranzas.vue'),
				meta: {
					menuIndex: 2,
					restricted: true
				}
			},
			{
				path: 'gestion_personas',
				name: 'Gestion Personas Admin',
				component: () => import('../views/admin/adminGestionPersonas.vue'),
				meta: {
					menuIndex: 3,
					restricted: true
				}
			},
			{
				path: 'gestion_clientes',
				name: 'Gestion Clientes Admin',
				component: () => import('../views/admin/adminGestionClientes.vue'),
				meta: {
					menuIndex: 4,
					restricted: true
				}
			},
			{
				path: 'gestion_tiendas',
				name: 'Gestion Tiendas Admin',
				component: () => import('../views/admin/adminGestionTiendas.vue'),
				meta: {
					menuIndex: 5,
					restricted: true
				}
			},
			{
				path: 'gestion_productos',
				name: 'Gestion Productos Admin',
				component: () => import('../views/admin/adminGestionProductos.vue'),
				meta: {
					menuIndex: 6,
					restricted: true
				}
			}
		]
	},
	{
		path: '*',
		component: () => import('../views/404.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const isPublic = to.matched.some((record) => record.meta.public);
	const isRestricted = to.matched.some((record) => record.meta.restricted);
	const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
	const loggedIn = !!TokenService.getToken();
	// debugger;
	if (loggedIn) {
		if (isPublic) {
			next();
		} else {
			if (!ApiService.getHeader()) {
				ApiService.setHeader().then(() => {
					next();
				});
			}
		}
	} else {
		if (isPublic) {
			next();
		} else next('/');
	}
	next();
});

export default router;
