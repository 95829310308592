import Vue from 'vue';

import moment from 'moment';
import VueMoment from 'vue-moment';

// Load Locales ('en' comes loaded by default)
require('moment/locale/es');

// Choose Locale
moment.locale('es');

Vue.use(VueMoment, { moment });

export default moment;