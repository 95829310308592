import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  },
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      dark: {
        base: "#4744CF"
      },
      light: {
        base: "#4744CF"
      }
    }
  }
});
