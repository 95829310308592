import ApiService from '../../services/api.service';
import UserService from '../../services/user.service';

export const fetchDetalleVenta = async ({ commit }, idVenta) => {

	commit('user/initRequest', null, { root: true });
	const url = ApiService.getResource(`/venta/${idVenta}`);
	try {
		commit('user/loadingRequest', true, { root: true });
		const detalleVenta = await UserService.getInfo(url);
		return detalleVenta.data.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
};

export const fetchVentasAgrupado = async ({ commit }, payload) => {

	commit('user/initRequest', null, { root: true });
	let status = true;
	let response;
	const url = ApiService.getResource('/venta/agrupado');
	try {
		commit('user/loadingRequest', true, { root: true });
		response = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
		status = response.data.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const deleteVenta = async ({ commit }, idVenta) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	let response;
	const url = ApiService.getResource('/venta/' + idVenta);
	try {
		commit('user/loadingRequest', true, { root: true });
		response = await ApiService.delete(url);
		status = response.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};