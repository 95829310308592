import ApiService from '../../services/api.service';

export const fetchListaTiendas = async ({ commit }) => {
	commit('user/initRequest', null, { root: true });
	const url = ApiService.getResource('/establecimiento/listar');
	try {
		commit('user/loadingRequest', true, { root: true });
		let response = await ApiService.get(url);
		commit('setListaTiendas', response.data.data);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		return false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
};

export const postNuevaTienda = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/establecimiento');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.post(url, payload);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const fetchDetalleTienda = async ({ commit }, idEstablecimiento) => {
	commit('user/initRequest', null, { root: true });
	const url = ApiService.getResource(`/establecimiento/${idEstablecimiento}`);
	try {
		commit('user/loadingRequest', true, { root: true });
		const detalleVenta = await ApiService.get(url);
		//console.log(listaVentasCredito);
		return detalleVenta.data.data;
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
};

export const putEditarTienda = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource(`/establecimiento/${payload.idEstablecimiento}`);
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.put(url, payload.tienda);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const postMovimientoProductoTienda = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource('/establecimiento/movimiento');
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.post(url, payload);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};

export const postAsignarNuevoProductoTienda = async ({ commit }, payload) => {
	commit('user/initRequest', null, { root: true });
	let status = true;
	const url = ApiService.getResource(`/establecimiento/${payload.idEstablecimiento}/producto`);
	try {
		commit('user/loadingRequest', true, { root: true });
		await ApiService.post(url, payload.producto);
	} catch (err) {
		commit(
			'user/setError',
			{ isError: true, errorMessage: err.response ? err.response.data.message : err.message  },
			{ root: true }
		);
		status = false;
	} finally {
		commit('user/loadingRequest', false, { root: true });
	}
	return status;
};
