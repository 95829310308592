import ApiService from './api.service';
import { TokenService } from './storage.service';

class AuthenticationError extends Error {
	constructor(errorCode, message) {
		super(message);
		this.name = this.constructor.name;
		this.message = message;
		this.errorCode = errorCode;
	}
}

const UserService = {
	/**
   * Login the user and store the access token to TokenService
   *
   * @returns access_token
   * @throws AuthenticationError
  **/
	login: async function(body) {
		try {
			const url = ApiService.getResource('/auth/login');
			const response = await ApiService.post(url, body);
			if (response.data.statusCode == 200) {
				TokenService.saveToken(response.data.data.token); //Debe ser response.data.data.token
				ApiService.setHeader();

				// Interceptor
				ApiService.mount401Interceptor();
				return response.data.data;
			}
		} catch (error) {
			throw new AuthenticationError(error.response.data.success, error.response.data.message);
		}
	},

	getRequestFromDates: async function(url, fechaInicio, fechaFin) {
		let urlSetted = url + '?fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin;
		return await ApiService.get(urlSetted);
	},

	getInfo: async function(url) {
		return await ApiService.get(url);
	},

	/**
  * Logout the current user by removing the token from storage.
  *
  * Will also remove 'Authorization Bearer <token>' header from future requests.
  **/
	logout() {
		TokenService.removeToken();
		ApiService.removeHeader();
		ApiService.unmount401Interceptor();
	}
};

export default UserService;

export { UserService, AuthenticationError };
