import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth.module';
import user from './user.module';
import tienda from './tienda';
import producto from './producto';
import trabajador from './trabajador';
import venta from './venta';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		user,
		tienda,
		producto,
		trabajador,
		venta
	}
});
