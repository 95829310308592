import ApiService from './../services/api.service';
import { TokenService } from '../services/storage.service';
import UserService from '../services/user.service';

const state = {
	loading: false,
	LoggedIn: false,
	infoUsuario: {},
	isError: false,
	authError: false,
	userType: '',
	errorMessage: 'Error',
	authErrorMessage: 'Error',
	validToken: TokenService.isTokenValid(),
	listaProductosClientes: [],
	listaEstablecimientos: []
};

const getters = {
	getVentasContado: (state) => {
		return state.ventasContado;
	},
	getVentasCredito: (state) => {
		return state.ventasCredito;
	},
	getCobranzas: (state) => {
		return state.cobranzas;
	},
	isLoggedIn: (state) => {
		return state.LoggedIn;
	},
	isError: (state) => {
		return state.isError;
	},
	getError: (state) => {
		return { error: state.isError, errorMessage: state.errorMessage };
	},
	getAuthError: (state) => {
		return { error: state.authError, authErrorMessage: state.authErrorMessage };
	},
	isValidToken: (state) => {
		return state.validToken;
	},
	getInfoUsuario(state) {
		return state.infoUsuario;
	},
	getUserType(state) {
		return state.userType;
	},
	isLoading(state) {
		return state.loading;
	},
	getListaProductosClientes(state) {
		return state.listaProductosClientes;
	},
	getListaProductosFiltrada(state) {
		return state.listaProductosClientes.productos ? state.listaProductosClientes.productos.filter((item) => item.idProductoVacio != null) : [];
	},
	getListaClientes(state) {
		return state.listaProductosClientes.clientes;
	},
	getListaEstablecimientos(state) {
		return state.listaEstablecimientos;
	}
};

const actions = {
	async fetchVentasContado({ commit }, payload) {
		const url = ApiService.getResource('/venta/contado');
		try {
			commit('loadingRequest', true);
			const listaVentasContado = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			return listaVentasContado.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchVentasCredito({ commit }, payload) {
		commit('initRequest');
		const url = ApiService.getResource('/venta/credito');
		try {
			commit('loadingRequest', true);
			const listaVentasCredito = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			//console.log(listaVentasCredito);
			return listaVentasCredito.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchVentasPorCobrar({ commit }, payload) {
		commit('initRequest');
		const url = ApiService.getResource('/cobranza/asignados');
		try {
			commit('loadingRequest', true);
			const listaVentasCredito = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			//console.log(listaVentasCredito);
			return listaVentasCredito.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchVentasPorCobrarAdmin({ commit }, payload) {
		commit('initRequest');
		const url = ApiService.getResource('/venta/cobranza');
		try {
			commit('loadingRequest', true);
			const listaVentasCredito = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			//console.log(listaVentasCredito);
			return listaVentasCredito.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchVentasTotalAdmin({ commit }, payload) {
		commit('initRequest');
		const url = ApiService.getResource('/venta');
		try {
			commit('loadingRequest', true);
			const listaVentasTotal = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			return listaVentasTotal.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchCobranzas({ commit }) {
		commit('initRequest');
		const url = ApiService.getResource('/venta/credito');
		try {
			const listaCobranzas = await ApiService.get(url);
			return listaCobranzas.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchInfoUsuario({ commit }) {
		commit('initRequest');
		const url = ApiService.getResource('/trabajador/perfil');
		try {
			const infoUsuario = await ApiService.get(url);
			commit('setInfoUsuario', infoUsuario.data.data[0]);
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchEncargadosRoles({ commit }) {
		commit('initRequest');
		const url = ApiService.getResource('/trabajador/listar');
		try {
			const infoRoles = await ApiService.get(url);
			return infoRoles.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async crearEncargado({ commit }, body) {
		commit('initRequest');
		const url = ApiService.getResource('/trabajador/asignar');
		try {
			let infoUsuario = await ApiService.post(url, body);
			this.listaProductosClientes = infoUsuario;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async eliminarEncargado({ commit }, body) {
		commit('initRequest');
		const url = ApiService.getResource('/trabajador/eliminar_asignar');
		try {
			await ApiService.post(url, body);
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async crearVenta({ commit }, requestBody) {
		commit('initRequest');
		const url = ApiService.getResource('/venta');
		let idVenta;
		try {
			commit('loadingRequest', true);
			idVenta = await ApiService.post(url, requestBody);
			return idVenta.data.data.idVenta;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			return false;
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchListaProductosClientes({ commit }) {
		commit('initRequest');
		const url = ApiService.getResource('/producto');
		try {
			commit('loadingRequest', true);
			let response = await ApiService.get(url);
			commit('setListaProductosClientes', response.data.data);
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			return false;
		} finally {
			commit('loadingRequest', false);
		}
	},

	async postRecuperarProductos({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource('/establecimiento/recuperar_producto/' + payload.idVenta);
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload.body);
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = true;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	},

	async postCobrarVenta({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource('/cobranza/crearCobranza/' + payload.idVenta);
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload);
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = true;
		} finally {
			commit('loadingRequest', false);	
		}
		return status;
	},

	async fetchDataTrabajadores({ commit }) {
		commit('initRequest');
		let response;
		const url = ApiService.getResource('/trabajador/listarTabla');
		try {
			commit('loadingRequest', true);
			response = await ApiService.get(url);
			return response.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async postNuevoTrabajador({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource('/trabajador');
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload);
			status = true;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = false;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	},

	async fetchStockAsignado({ commit }, idTrabajador) {
		commit('initRequest');
		let response;
		const url = ApiService.getResource('/trabajador/stock/' + idTrabajador);
		try {
			commit('loadingRequest', true);
			response = await ApiService.get(url);
			return response.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async postNuevoCliente({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource('/cliente');
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload);
			status = true;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = false;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	},

	async putEditarCliente ({ commit }, payload) {
		commit('user/initRequest', null, { root: true });
		let status = true;
		const url = ApiService.getResource(`/cliente/${payload.idCliente}`);
		try {
			commit('user/loadingRequest', true, { root: true });
			await ApiService.put(url, payload.cliente);
		} catch (err) {
			commit(
				'user/setError',
				{ isError: true, errorMessage: err.response ? err.response.data.message : err.message },
				{ root: true }
			);
			status = false;
		} finally {
			commit('user/loadingRequest', false, { root: true });
		}
		return status;
	},

	async postAsignarNuevoProductoTrabajador({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource(`/trabajador/producto/${payload.idTrabajador}`);
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload.producto);
			status = true;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = false;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	},

	async postMovimientoProductoPersonaTienda({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource(`/trabajador/producto/${payload.idTrabajador}/mover`);
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload.producto);
			status = true;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = false;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	},

	async fetchMovimientos({ commit }, payload) {
		commit('initRequest');
		let response;
		const url = ApiService.getResource('/movimiento/listar');
		try {
			commit('loadingRequest', true);
			response = await UserService.getRequestFromDates(url, payload.horaCeroInicio, payload.horaCeroFin);
			return response.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async fetchResumen({ commit }, payload) {
		commit('initRequest');
		let response;
		try {
			commit('loadingRequest', true);
			response = await UserService.getRequestFromDates('/trabajador/' + payload.idTrabajador + '/resumen_ventas', payload.fechaInicioRequest, payload.fechaFinRequest);
			return response.data.data;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
		} finally {
			commit('loadingRequest', false);
		}
	},

	async postCuadrarVenta({ commit }, payload) {
		commit('initRequest');
		let status = false;
		const url = ApiService.getResource('/trabajador/cerrar_inventario');
		try {
			commit('loadingRequest', true);
			await ApiService.post(url, payload);
			status = true;
		} catch (err) {
			commit('setError', { isError: true, errorMessage: err.response ? err.response.data.message : err.message });
			status = false;
		} finally {
			commit('loadingRequest', false);
		}
		return status;
	}
};

const mutations = {
	initRequest(state) {
		state.error = '';
		state.isError = false;
		state.authErrorMessage = '';
		state.authError = false;
	},
	setVentasContado(state, listaVentasContado) {
		state.ventasContado = listaVentasContado;
	},
	setVentasCredito(state, listaVentasCredito) {
		state.ventasCredito = listaVentasCredito;
	},
	setCobranzas(state, listaCobranzas) {
		state.cobranzas = listaCobranzas;
	},
	setInfoUsuario(state, infoUsuario) {
		state.infoUsuario = infoUsuario;
		state.userType = infoUsuario.rol;
	},
	setError(state, errorResponse) {
		state.errorMessage = errorResponse.errorMessage;
		state.isError = errorResponse.isError;
	},

	loadingRequest(state, isLoading) {
		state.loading = isLoading;
	},

	setListaProductosClientes(state, nuevaLista) {
		state.listaProductosClientes = nuevaLista;
	},

	setInvalidTokenValue(state, isInvalid) {
		state.invalidToken = isInvalid;
	},
	setUserType(state, userType) {
		state.userType = userType;
	},
	setUserAuthError(state, { isError, authErrorMessage }) {
		state.authErrorMessage = authErrorMessage;
		state.authError = isError;
	},
	setListaEstablecimiento(state, lista) {
		state.listaEstablecimientos = lista;
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
