import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from './plugins/moment';
import chartjs from './plugins/chart'

import ApiService from './services/api.service';
import { TokenService } from './services/storage.service';

Vue.config.productionTip = false;

// //Inicializa  el servicio de API
//https://distribuidora-backend.herokuapp.com/api
ApiService.init(
	process.env.NODE_ENV == 'production'
		? 'https://www.altokeayacucho.com/api'
		: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api'
);
// //Inicializa Interceptor
ApiService.mount401Interceptor();

if (TokenService.getToken()) {
	ApiService.setHeader();
	TokenService.setTokenValidValue(true);
} else if (TokenService.getToken() == null) {
	TokenService.setTokenValidValue(false);
}

new Vue({
	router,
	store,
	vuetify,
	moment,
	render: (h) => h(App)
}).$mount('#app');
