<template>
  <v-app>
    <v-content transition="slide-x-transition">
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({})
};
</script>

<style>
html {
  overflow-y: auto;
}
</style>
